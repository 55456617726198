import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object) {
      //debugger;
     }

  isPlatformBrowser() {
    return isPlatformBrowser(this.platformId)
  }

  isPlatformServer() {
    return isPlatformServer(this.platformId)
  }

  log(message: string){
    if(this.isPlatformBrowser()){
      console.log('[BROWSER debug] ', message);
      return;
    }
    if(this.isPlatformServer()){
      console.log('[SERVER debug] ', message);
      return;
    }
    console.log('[UNKNOWN debug] ', message);
  }
}