import { Injectable, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as CryptoJS from 'crypto-js';
import { Observable } from 'rxjs';
import {
  DataLayerAccount,
  DataLayerCookie,
  DataLayerDTO,
  DataLayerEcommerce,
  TrackingDTO,
  DataLayerClick,
  DataLayerPageView,
} from '../models/tracking.model';
import { selectUser } from '../store/auth/auth.selectors';
import { PlatformService } from './plateform.service';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  user$: Observable<any>;
  
  constructor(
    private platformService: PlatformService,
    private store: Store) {

    // Ensure gtag is defined
    // if(this.platformService.isPlatformBrowser()){
    //   if (!window.gtag) {
    //     console.log('GTM is not loaded yet.');
    //     return;
    //   }
    // }
  }

  private createDataLayerDTO(trackingDTO: TrackingDTO): DataLayerDTO {

    let dateNow = new Date().toISOString();
    
    let dataLayerDTO: DataLayerDTO = {
      event: trackingDTO.eventType,
      date: dateNow,
      ecommerce: null,
      page: null,
      click: null,
      account: null,
      consent: null
    };
    
    this.user$ = this.store.select(selectUser);
    this.user$.subscribe(user => {
      if(user?.id && user.createdAt) {
        let dateTracked = new Date(user?.createdAt); 
        let dataLayerAccount: DataLayerAccount = 
        {
          accountId: user?.id,
          userEmail: this.hashData(user.email),
          userPhone: this.hashData(user.phone),
          dateCreation: dateTracked?.toISOString(),
          connexionsCount: user.connexionsCount,
          applicationCount: null,
          identityProvider: user?.identityProvider,
          subscriptionType: user?.subscriptionType,
          userType: user?.role
        };
        dataLayerDTO.account = dataLayerAccount;
      } 
      dataLayerDTO.page = this.createPageViewData(trackingDTO);
      return this.switchCaseEventType(trackingDTO, dataLayerDTO);
    });
    return dataLayerDTO;
  }

  track(trackingDTO: TrackingDTO): void {
    if (this.platformService.isPlatformServer()) {
      return; // Only execute in the browser
    }
    let dataLayer = this.createDataLayerDTO(trackingDTO);
    let data = this.removeNullKeys(dataLayer); 
    this.pushTag(data);
  }

  // --------------
  // UTILS
  // --------------

  /**
   * Push a tag to the GTM dataLayer
   * @param data - Object containing the event data to push to GTM
   */
  public pushTag(data: Record<string, any>): void {
    try {
      if (!data || typeof data !== 'object') {
        throw new Error('Invalid data format: pushTag expects an object.');
      }

      // Add a timestamp to the data for debugging or tracking purposes
      const tagWithTimestamp = {
        ...data,
        eventTimestamp: new Date().toISOString(),
      };

      // Push the data to the GTM dataLayer
      window.dataLayer.push(tagWithTimestamp);

      console.log('[GTMService] Tag pushed successfully:', tagWithTimestamp);
    } catch (error) {
      console.error('[GTMService] Failed to push tag:', error.message);
    }
  }

  private switchCaseEventType(trackingDTO: TrackingDTO, dataLayerDTO: DataLayerDTO){
    switch (trackingDTO.eventType)  {
     
      case 'clickAction':
        dataLayerDTO.click = this.createClickData(trackingDTO);
        dataLayerDTO.ecommerce = this.fillEcommerce(trackingDTO);
        break;
      case 'pageView':
        break;
      case 'applicationCreated':
        dataLayerDTO.ecommerce = this.fillEcommerce(trackingDTO);
        break;
      case 'register':
        break;
      case 'login': 
        break;
      // case 'consent_update': //todo
      //   dataLayerDTO.consent = this.createCookieConsentData(trackingDTO);
      //   break;
      default:
        console.warn(`Unhandled event type: ${trackingDTO.eventType}`);
        break;
    }
    return dataLayerDTO;
  }

  private createClickData(trackingDTO: TrackingDTO): DataLayerClick {
    return {
      btnName: trackingDTO.btnName,
      actionDescription: trackingDTO.actionDescription
    };
  }

  private createPageViewData(trackingDTO: TrackingDTO): DataLayerPageView {
    return {
      pageUrl: trackingDTO.pageUrl,
      pageTitle: trackingDTO.pageTitle
    };
  }

  private createCookieConsentData(consent: 'granted' | 'denied'): DataLayerCookie {
    return {
      ad_storage: consent,
      ad_user_data: consent,
      ad_personalization: consent,
      analytics_storage: consent,
      functionality_storage: consent,
      personalization_storage: consent,
      security_storage: 'granted'
    };
  }


  private hashData(data: string) {
    if (!data) {
      return null;
    }
    return CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex);
  }

  private fillEcommerce(trackingDTO: TrackingDTO): DataLayerEcommerce {
    return {
      formName: trackingDTO.formName,
      value: 0,
      affiliation: 'no',
      tax: 20,
      transactionId: null,
      items: [
        {
          price: null,
          currency: 'EUR',
          quantity: 1,
          itemBrand: 'pirog',
          itemCategory: trackingDTO.templateId,
          itemCategory2: null,
          application: {
            templateId: trackingDTO.templateId,
            templateName: trackingDTO.templateName,
            applicationId: trackingDTO.applicationId,
            applicationName: trackingDTO.applicationName,
            domainName: trackingDTO.domainName,
            subscriptionId: trackingDTO.subscriptionId,
          },
        },
      ],
    };
  }

  removeNullKeys<T extends object>(obj: T): Partial<T> {
    return Object.keys(obj).reduce((acc, key) => {
      const typedKey = key as keyof T;
      if (obj[typedKey] !== null) {
        acc[typedKey] = obj[typedKey];
      }
      return acc;
    }, {} as Partial<T>);
  }

  cookieConsentDefault() {
    // Initialize dataLayer and define the gtag function on the window object
    //gtag('consent', 'default', )
    let cookie = this.createCookieConsentData("denied")
    // let data = [
    //   "consent","default", cookie
    // ]
    //this.gtmService.pushTag(data);

    window.gtag('consent', 'default', cookie);
  }

  cookieConsentGranted() {
    // Initialize dataLayer and define the gtag function on the window object
    let cookie = this.createCookieConsentData("granted")
    // let data = [
    //   "consent","default", cookie
    // ]
    // this.gtmService.pushTag(data);
    window.gtag('consent', 'update', cookie);
  }
  
}
