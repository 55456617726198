export const environment = {
  production: true,
  noindex: true,
  baseUrl: "",
  baseUrlSsr: "https://dev.env.pirog.io",
  seoUrl : "https://dev.env.pirog.io",
  isExplorerActivated: true,
  GOOGLE_CLIENT_ID: "727063448365-3e8p0f2gln55ramgptv8n93nug3knmdt.apps.googleusercontent.com",
  AWS_ACCESS_KEY_ID: "AKIA567W3F4UUMKTRAGH",
  AWS_SECRET_ACCESS_KEY: "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",
  AWS_REGION: "eu-west-3",
  OPS_BUCKET: "makit-pirog-dev-shared-ops",
  PITRANSFER_BUCKET: "makit-pirog-dev-pitransfer-inputs",
  SHARED_FRONTS_BUCKET: "makit-pirog-dev-shared-fronts",
  S3_PREFIXE: "clients/apps",
  PI_TRANSFER_GB_LIMIT: 2,
  PI_TRANSFER_RENAME_GB_LIMIT: 0.01,
  stripePortalUrl: "https://billing.stripe.com/p/login/test_4gw7voeNy8Ca1DGfYY",
  stripePublicKey: "pk_test_51McbbFFXZHkCQxfIDvyuZPJign1zpH0fhOvcE6VZxkiFYIHMPglLGaXGUUv2jT2BYJF9hy6GpOv0uMIsM52gaVIn00O0TkW6Jp",
  RECAPTCHA_SECRET_KEY:"6LcEEFkpAAAAAJ-9iP3cXcGo81TrJIU5thtMx3u2",
  PAGE_BUILDER_URL:"dev.pirog.io",
  ID_CLOUDFRONT_CLIENT: 'https://d8dz8apt1lxt8.cloudfront.net',
  GTM_ID:"GTM-PD8H6LK7",
  GTM_AUTH:"PF3SrKtnDjLw6GreFc6N9Q",
  GTM_ENV:"env-10",
  GTM_RESSOURCE_PATH:"https://dev.env.pirog.io"
};
