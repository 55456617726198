import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { SocketService } from 'src/app/services/socket.service';
import { ToastService } from 'src/app/services/toaster.service';
import { loadUserById, verifyAccountAttempt } from 'src/app/store/auth/auth.actions';
import { selectUser, selectUserId } from 'src/app/store/auth/auth.selectors';
import { selectIsConnectedToWS } from 'src/app/store/repository/repository.selector';

@Component({
  selector: 'app-have-to-verify-account',
  templateUrl: './have-to-verify-account.component.html',
  styleUrls: ['./have-to-verify-account.component.scss']
})
export class HaveToVerifyAccountComponent implements OnInit, OnDestroy {
  user: User;
  private _subscriptions: Subscription = new Subscription();
  constructor(
    private store: Store,
    private socketService: SocketService,
    private toastService: ToastService) {}

  ngOnInit(): void {
      const _user = this.store.select(selectUser).subscribe((user) => {
          this.user = user;
      });
      this._subscriptions.add(_user);
      this._userId();
  }

  private _userId() {
      const _userId = this.store.select(selectUserId).subscribe((userId) => {
          this.store.dispatch(loadUserById());
          const _this = this;
          const _isConnected = this.store.select(selectIsConnectedToWS).subscribe((isConnected) => {
              if (isConnected) {
                  this.socketService.stompClientWS.subscribe(
                      '/start/users/' + userId + '/verified',
                      function (response) {
                          _this.store.dispatch(loadUserById());
                          let message = this.translateService.instant("toastr.success_message_sent")
                          _this.toastService.success(message);
                      }
                  );
              }
          });
          this._subscriptions.add(_isConnected);
      });
      this._subscriptions.add(_userId);
  }

  verifyAccountAttempt() {
      this.store.dispatch(verifyAccountAttempt({ idUser: this.user.id }));
  }

  ngOnDestroy(): void {
      this._subscriptions.unsubscribe();
  }
}
